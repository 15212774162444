import "./App.css";

import React from "react";

import {
   disableBodyScroll,
   enableBodyScroll,
   clearAllBodyScrollLocks,
} from "body-scroll-lock";

import { images, thumbs } from "./images";

import GalleryImage from "./Components/GalleryImage.js";
import FullImage from "./Components/FullImage.js";

import banner from "./images/banner.jpg";
import bio from "./images/bio.jpg";

import cross from "./images/cross.png";

let imageTitles = [
   "Through the Pohutukawa",
   "Stranded Mooring",
   "Lazy Nikau",
   "Tranquil Mooring",
   "Cloud Over the Mayor",
   "Wash Day",
   "Nikau Sunrise",
   "Early Evening Walk",
   "Nikau Magic",
   "The Boathouse",
   "The Break",
   "Sunset Walk",
   "Above the Bay",
   "Dune Walk",
   "Three Mile Creek",
   "Kiwi Summer",
   "Windswept",
   "The Getaway",
   "Little Church",
   "Serenity",
   "Fiery Sunset",
   "Heart of Venice",
   "Colours of Italy",
   "Farmhouse in San Casciano",
   "Tapestry of Tuscany",
   "Enchantment",
   "Lazy Summer",
   "Secluded Creek",
   "Just Santorini",
   "Turquoise Waters",
   "Walk in the Meadow",
   "Tranquility",
   "After the Storm",
   "Timid Kereru",
];

class App extends React.Component {
   targetElement = null;

   state = {
      showFullImage: false,
      selectedImage: {
         index: null,
         thumbLoaded: false,
         imageLoaded: false,
      },
      loaded: false,
      note: "",
      page: "home",
      showMobileMenu: false,
      scrollPosition: 0,
   };

   topPosition = 0;

   constructor() {
      super();
      window.addEventListener("resize", () => {
         // We execute the same script as before
         this.setHeightVariables();
      });

      window.addEventListener("orientationchange", () => {
         // We execute the same script as before
         setTimeout(() => {
            this.setHeightVariables();
            // window.scrollTo(0, 0);
         }, 1000);
      });
      this.setHeightVariables();

      var _self = this;

      document.addEventListener(
         "touchmove",
         function (e) {
            // if (_self.state.showFullImage) {
            //    e.preventDefault();
            // }
         },
         { passive: false }
      );
   }

   setHeightVariables() {
      // var width;

      let height = window.innerHeight;
      let vh = height * 0.01;

      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
   }
   // let page="home"

   componentDidUpdate(prevProps, prevState) {
      // if (prevState.showFullImage && !this.state.showFullImage) {
      //    console.log("fix position");
      //    // console.log("fix scroll");
      //    window.scrollTo(0, this.state.scrollPosition);
      // }
   }

   render() {
      let page;
      if (this.state.page == "home") {
         page = (
            <div className="gallery">
               {thumbs.map((thumb, i) => {
                  return (
                     <GalleryImage
                        key={i}
                        thumb={thumb}
                        onSelect={(image) => {
                           this.targetElement =
                              document.querySelector("#testTarget");

                           disableBodyScroll(this.targetElement);
                           var selectedImage = {
                              ...this.state.selectedImage,
                           };
                           //only reset loaded flags if image has changed. prevents bug where reviewing same image won't set loaded to true
                           if (i != this.state.selectedImage.index) {
                              console.log("change index to " + i);
                              selectedImage = {
                                 index: i,
                                 thumbLoaded: false,
                                 imageLoaded: false,
                              };
                           }

                           console.log("innerHeight", window.innerHeight);
                           this.setState({
                              showFullImage: true,
                              scrollPosition: window.pageYOffset,

                              selectedImage: selectedImage,
                              loaded: false,
                              note: imageTitles[i],
                              imageLoaded: false,
                           });
                        }}
                     />
                  );
               })}
            </div>
         );
      } else if (this.state.page == "about") {
         page = (
            <div className="About">
               <h1>About</h1>

               <div className="banner">
                  <img src={banner} />
               </div>

               <div className="row">
                  <div className="left">
                     <img src={images[20]} />
                  </div>
                  <div className="right">
                     <h2>My Work</h2>
                     As a teenager Richard received private tuition from two
                     Waikato artists, gaining the strict disciplines of good
                     composition, of always drawing no matter the medium or
                     technique, and essential use of colour, both subtle and
                     bold. After marrying Gill and with a young family, Richard
                     concentrated on graphic arts, establishing a freelance
                     business which he operated successfully for more than 30
                     years. In recent years he focuses more on stylising New
                     Zealand’s tapestry of flora and fauna along with his fond
                     memories of 'Kiwiana'. Recently he held a popular sellout
                     exhibition in Wintec’s Ramp Gallery, Hamilton, titled “Land
                     • Water • Sky”. He always enjoys drawing New Zealand’s
                     great outdoors using most mediums including pens, pastels,
                     oils and watercolours. He has an eye for light and
                     colour-inspired landscapes, keeping a camera at the ready.
                     Richard always believes in the adage, “The view from my
                     window is a constant that is forever changing” – the same
                     view capable of being successfully rendered many times in
                     different styles and colours. Richard explores with his
                     mediums and application techniques, finding exquisite
                     beauty in raw, earthy and powerful colours.
                  </div>
               </div>

               <div className="row">
                  <div className="left">
                     <h2>Biography</h2>
                     Donec eu velit at ante consequat pretium. Vestibulum
                     tincidunt ullamcorper auctor. Maecenas ipsum lorem,
                     placerat vel vulputate in, fermentum a ligula. Orci varius
                     natoque penatibus et magnis dis parturient montes, nascetur
                     ridiculus mus. In quis ipsum mauris. Curabitur ipsum orci,
                     suscipit sit amet libero vitae, cursus pellentesque ante.
                     Mauris ac elit eget mi fermentum eleifend. Nullam aliquet
                     quis lectus consequat posuere. Aenean aliquet feugiat sem
                     non porta. Nullam eget volutpat elit. Cras odio lacus,
                     tempor ut nisl ac, hendrerit lobortis lectus.
                  </div>
                  <div className="right">
                     <img src={bio} />
                  </div>
               </div>
            </div>
         );
      }
      let style = {};
      let headerStyle = {};
      if (this.state.showFullImage) {
         // console.log(this.state.scrollPosition);
         // style.top = -this.state.scrollPosition + "px";
         console.log("innerHeight2", window.innerHeight);
         //headerStyle.height = "500px";
      }

      return (
         <div
            className={`App${this.state.showFullImage ? " block" : ""}`}
            style={style}
         >
            <div
               className={`main ${
                  this.state.showFullImage ? "blur" : "unblur"
               }`}
            >
               <div className="leftColumn">
                  <div className="leftColumn__inner">
                     <a
                        onClick={() => {
                           this.setState({ page: "home" });
                        }}
                     >
                        <h1 id="testTarget">Richard Stowers</h1>
                     </a>

                     <nav>
                        <ul>
                           <li
                              className={
                                 this.state.page == "home" ? "selected" : null
                              }
                           >
                              <a
                                 onClick={() => {
                                    this.setState({ page: "home" });
                                 }}
                              >
                                 Home
                              </a>
                           </li>
                           <li>
                              <a href="/">Galleries</a>
                           </li>
                           <li
                              className={
                                 this.state.page == "about" ? "selected" : null
                              }
                           >
                              <a
                                 onClick={() => {
                                    this.setState({ page: "about" });
                                 }}
                              >
                                 About
                              </a>
                           </li>
                           <li>
                              <a href="/">Contact</a>
                           </li>
                        </ul>
                     </nav>
                  </div>
               </div>

               <div>
                  {/* <header
                  style={headerStyle}
                  onTouchStart={(e) => {
                     e.preventDefault();
                  }}
               >
                  <h1>Richard Stowers</h1>

                  <div
                     id="nav-icon3"
                     className={this.state.showMobileMenu ? "open" : ""}
                     onTouchEnd={() => {
                        if (!this.state.showMobileMenu) {
                           this.topPosition = `-${window.scrollY}`;
                        }

                        this.setState({
                           showMobileMenu: !this.state.showMobileMenu,
                        });
                     }}
                  >
                     <span></span>
                     <span></span>
                     <span></span>
                     <span></span>
                  </div>
               </header> */}

                  {page}
               </div>
            </div>

            <div
               className={`BlurBackground ${
                  this.state.showFullImage ? "blur" : "unblur"
               }`}
            ></div>

            {/* {this.state.showFullImage ? (
               <div
                  className="testImage"
                  style={{ top: this.state.scrollPosition + 100, left: "5%" }}
               >
                  <img src={thumbs[0]} />
               </div>
            ) : null} */}

            <FullImage
               thumbnail={thumbs[this.state.selectedImage.index]}
               image={images[this.state.selectedImage.index]}
               thumbLoaded={this.state.selectedImage.thumbLoaded}
               imageLoaded={this.state.selectedImage.imageLoaded}
               onThumbLoaded={() => {
                  this.setState({
                     selectedImage: {
                        ...this.state.selectedImage,
                        thumbLoaded: true,
                     },
                  });
               }}
               onImageLoaded={() => {
                  this.setState({
                     selectedImage: {
                        ...this.state.selectedImage,
                        imageLoaded: true,
                     },
                  });
               }}
               close={(event) => {
                  this.setState({
                     showFullImage: false,
                     imageLoaded: false,
                  });
               }}
               showFullImage={this.state.showFullImage}
            />
            <img
               className={`cross ${this.state.showFullImage ? " show" : ""}`}
               src={cross}
               onClick={() => {
                  enableBodyScroll(this.targetElement);
                  this.setState({
                     showFullImage: false,
                  });
               }}
            />
         </div>
      );
   }
}

export default App;
