import react from "react";

import { useLayoutEffect, useState } from "react";

import "./FullImage.css";
var isZoom = 1;

function useWindowSize() {
   const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

   useLayoutEffect(() => {
      function updateSize() {
         let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;

         var vw = window.innerWidth;
         var vh = window.innerHeight;
         // isZoom = zoom;
         // if (zoom > 100) {
         //vw = Math.max(window.outerWidth || 0, window.innerWidth || 0);
         // vh = Math.max(window.outerHeight || 0, window.innerHeight || 0);
         //    isZoom = zoom;
         // }

         setSize([vw, vh]);
         // setSize([
         //    Math.round(window.devicePixelRatio * window.innerWidth),
         //    Math.round(window.devicePixelRatio * window.innerHeight),
         // ]);
         //setSize([document.width, document.height]);
      }

      function onRotate() {
         setTimeout(() => {
            console.log("rotate");
            // window.scrollTo(0, 0);
            updateSize();
         }, 1000);
      }
      window.addEventListener("resize", updateSize);
      window.addEventListener("orientationchange", onRotate);
      // document.addEventListener("touchup", updateSize);

      updateSize();
      return () => {
         window.removeEventListener("resize", updateSize);
         window.removeEventListener("orientationchange", onRotate);
         // document.removeEventListener("touchup", updateSize);
      };
   }, []);
   return size;
}

export default (props) => {
   const [imageSize, setImageSize] = useState([1, 1]);

   const onThumbnailLoad = ({ target: img }) => {
      setImageSize([img.naturalWidth, img.naturalHeight]);
      props.onThumbLoaded();
   };
   const onLargeImageLoad = ({ target: img }) => {
      props.onImageLoaded();
   };

   const [width, height] = useWindowSize();
   const screenRatio = width / height;
   const imageRatio = imageSize[0] / imageSize[1];

   var displayWidth = imageSize[0];
   var displayHeight = imageSize[1];

   if (imageRatio > screenRatio) {
      displayWidth = width * 0.75;
      displayHeight = displayWidth / imageRatio;
   } else {
      displayHeight = height * 0.75;
      displayWidth = displayHeight * imageRatio;
   }

   var show = props.showFullImage && props.thumbLoaded;
   var imageSource = props.imageLoaded ? props.image : props.thumbnail;

   console.log("FullImage, imageloaded", props.imageLoaded);

   var frameWidth = width * 0.015; //Math.max(displayWidth, displayHeight) * 0.02;

   return (
      <div
         id="FullImage"
         className={`FullImage${show ? " show" : ""}`}
         // style={{
         //    top: height * 0.5,
         //    left: width * 0.5,
         //    marginLeft: -width * 0.5,
         //    marginTop: -height * 0.5,
         // }}
         // onTouchStart={(event) => {
         //    event.preventDefault();
         // }}
         // onTouchMove={(event) => {
         //    event.preventDefault();
         // }}
         //style={this.state.loaded ? {} : { display: "none" }}
         // onClick={(event) => {
         //    if (event.target == event.currentTarget) {
         //       this.setState({
         //          showFullImage: false,
         //       });
         //       event.stopPropagation();
         //    }
         // }}
      >
         <img
            className="thumbnail"
            width={displayWidth}
            height={displayHeight}
            style={{
               left: (width - displayWidth) * 0.5,
               top: (height - displayHeight) * 0.5,
            }}
            src={props.thumbnail}
            onLoad={onThumbnailLoad}
         />

         <img
            className={`image${props.imageLoaded && show ? " show" : ""}`}
            // style={{ display: props.imageLoaded ? "block" : "none" }}
            width={displayWidth}
            height={displayHeight}
            style={{
               left: (width - displayWidth) * 0.5,
               top: (height - displayHeight) * 0.5,
            }}
            src={props.image}
            onLoad={onLargeImageLoad}
         />

         <div
            className="frame"
            style={{
               width: displayWidth + frameWidth * 2,
               height: displayHeight + frameWidth * 2,
               left: (width - (displayWidth + frameWidth * 2)) * 0.5,
               top: (height - (displayHeight + frameWidth * 2)) * 0.5,
               border: `${frameWidth}px solid white`,
            }}
         ></div>

         {/* <div className="note">{this.state.note}</div> */}
      </div>
   );
};
