import image1 from "./images/artwork/1-ThroughThePohutukawa.jpeg";
import image2 from "./images/artwork/2-StrandedMooring.jpeg";
import image3 from "./images/artwork/3-LazyNikau.jpeg";
import image4 from "./images/artwork/4-TranquilMooring.jpeg";
import image5 from "./images/artwork/5-CloudOverTheMayor.jpeg";
import image6 from "./images/artwork/6-WashDay.jpeg";
import image7 from "./images/artwork/7-NikauSunrise.jpeg";
import image8 from "./images/artwork/8-EarlyEveningWalk.jpeg";
import image9 from "./images/artwork/9-NikauMagic.jpeg";
import image10 from "./images/artwork/10-TheBoathouse.jpeg";
import image11 from "./images/artwork/11-TheBreak.jpeg";
import image12 from "./images/artwork/12-SunsetWalk.jpeg";
import image13 from "./images/artwork/13-AboveTheBay.jpeg";
import image14 from "./images/artwork/14-DuneWalk.jpeg";
import image15 from "./images/artwork/15-ThreeMileCreek.jpeg";
import image16 from "./images/artwork/16-KiwiSummer.jpeg";
import image17 from "./images/artwork/17-Windswept.jpeg";
import image18 from "./images/artwork/18-TheGetaway.jpeg";
import image19 from "./images/artwork/19-LittleChurch.jpeg";
import image20 from "./images/artwork/20-Serenity.jpeg";
import image21 from "./images/artwork/21-FierySunset.jpeg";
import image22 from "./images/artwork/22-HeartOfVenice.jpeg";
import image23 from "./images/artwork/23-ColoursOfItaly.jpeg";
import image24 from "./images/artwork/24-FarmhouseInSanCasciano.jpeg";
import image25 from "./images/artwork/25-TapestryOfTuscany.jpeg";
import image26 from "./images/artwork/26-Enchantment.jpeg";
import image27 from "./images/artwork/27-LazySummer.jpeg";
import image28 from "./images/artwork/28-SecludedCreek.jpeg";
import image29 from "./images/artwork/29-JustSantorini.jpeg";
import image30 from "./images/artwork/30-TurquoiseWaters.jpeg";
import image31 from "./images/artwork/31-WalkInTheMeadow.jpeg";
import image32 from "./images/artwork/32-Tranquility.jpeg";
import image33 from "./images/artwork/33-AfterTheStorm.jpeg";
import image34 from "./images/artwork/34-TimidKereru.jpeg";
import image35 from "./images/artwork/35-BeachTui.jpeg";
import image36 from "./images/artwork/36-BeachKereru.jpeg";
import image37 from "./images/artwork/37-PurpleHaze.jpeg";
import image38 from "./images/artwork/38-HeightOfSummer.jpeg";

import image1t from "./images/artwork/thumbs/1-ThroughThePohutukawa.jpeg";
import image2t from "./images/artwork/thumbs/2-StrandedMooring.jpeg";
import image3t from "./images/artwork/thumbs/3-LazyNikau.jpeg";
import image4t from "./images/artwork/thumbs/4-TranquilMooring.jpeg";
import image5t from "./images/artwork/thumbs/5-CloudOverTheMayor.jpeg";
import image6t from "./images/artwork/thumbs/6-WashDay.jpeg";
import image7t from "./images/artwork/thumbs/7-NikauSunrise.jpeg";
import image8t from "./images/artwork/thumbs/8-EarlyEveningWalk.jpeg";
import image9t from "./images/artwork/thumbs/9-NikauMagic.jpeg";
import image10t from "./images/artwork/thumbs/10-TheBoathouse.jpeg";
import image11t from "./images/artwork/thumbs/11-TheBreak.jpeg";
import image12t from "./images/artwork/thumbs/12-SunsetWalk.jpeg";
import image13t from "./images/artwork/thumbs/13-AboveTheBay.jpeg";
import image14t from "./images/artwork/thumbs/14-DuneWalk.jpeg";
import image15t from "./images/artwork/thumbs/15-ThreeMileCreek.jpeg";
import image16t from "./images/artwork/thumbs/16-KiwiSummer.jpeg";
import image17t from "./images/artwork/thumbs/17-Windswept.jpeg";
import image18t from "./images/artwork/thumbs/18-TheGetaway.jpeg";
import image19t from "./images/artwork/thumbs/19-LittleChurch.jpeg";
import image20t from "./images/artwork/thumbs/20-Serenity.jpeg";
import image21t from "./images/artwork/thumbs/21-FierySunset.jpeg";
import image22t from "./images/artwork/thumbs/22-HeartOfVenice.jpeg";
import image23t from "./images/artwork/thumbs/23-ColoursOfItaly.jpeg";
import image24t from "./images/artwork/thumbs/24-FarmhouseInSanCasciano.jpeg";
import image25t from "./images/artwork/thumbs/25-TapestryOfTuscany.jpeg";
import image26t from "./images/artwork/thumbs/26-Enchantment.jpeg";
import image27t from "./images/artwork/thumbs/27-LazySummer.jpeg";
import image28t from "./images/artwork/thumbs/28-SecludedCreek.jpeg";
import image29t from "./images/artwork/thumbs/29-JustSantorini.jpeg";
import image30t from "./images/artwork/thumbs/30-TurquoiseWaters.jpeg";
import image31t from "./images/artwork/thumbs/31-WalkInTheMeadow.jpeg";
import image32t from "./images/artwork/thumbs/32-Tranquility.jpeg";
import image33t from "./images/artwork/thumbs/33-AfterTheStorm.jpeg";
import image34t from "./images/artwork/thumbs/34-TimidKereru.jpeg";
import image35t from "./images/artwork/thumbs/35-BeachTui.jpeg";
import image36t from "./images/artwork/thumbs/36-BeachKereru.jpeg";
import image37t from "./images/artwork/thumbs/37-PurpleHaze.jpeg";
import image38t from "./images/artwork/thumbs/38-HeightOfSummer.jpeg";

let images = [
   image1,
   image2,
   image3,
   image4,
   image5,
   image6,
   image7,
   image8,
   image9,
   image10,
   image11,
   image12,
   image13,
   image14,
   image15,
   image16,
   image17,
   image18,
   image19,
   image20,
   image21,
   image22,
   image23,
   image24,
   image25,
   image26,
   image27,
   image28,
   image29,
   image30,
   image31,
   image32,
   image33,
   image34,
   image35,
   image36,
   image37,
   image38,
];

let thumbs = [
   image1t,
   image2t,
   image3t,
   image4t,
   image5t,
   image6t,
   image7t,
   image8t,
   image9t,
   image10t,
   image11t,
   image12t,
   image13t,
   image14t,
   image15t,
   image16t,
   image17t,
   image18t,
   image19t,
   image20t,
   image21t,
   image22t,
   image23t,
   image24t,
   image25t,
   image26t,
   image27t,
   image28t,
   image29t,
   image30t,
   image31t,
   image32t,
   image33t,
   image34t,
   image35t,
   image36t,
   image37t,
   image38t,
];

export { images, thumbs };
