import react from "react";

import mag from "../images/mag.png";

export default (props) => {

   const onImgLoad = ({target:img}) => {
      // console.log(props.thumb)
      // console.log("width: "+ img.naturalWidth + " / height: "+ img.naturalHeight)
      // this.setState({dimensions:{height:img.offsetHeight,
      //                            width:img.offsetWidth}});
  }


   return (
      <div
         className="GalleryImage"
         onClick={() => {
            props.onSelect(props.image);
         }}
         onLoad={onImgLoad}
      >
         <img className="mag" src={mag} />
         <img className="image" src={props.thumb} />
      </div>
   );
};
